import React from 'react';

const IconTriangle = (props) => (
  <svg {...props} viewBox="0 0 24 24" width="24" height="24">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" fillRule="nonzero" transform="translate(3 4)">
        <path d="M18 17L0 17 9.00033061 0z"></path>
      </g>
    </g>
  </svg>
)

export default IconTriangle;
