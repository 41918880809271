import React from 'react';

const IconTitle = (props) => (
  <svg {...props} height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path fill="currentColor" d="M5 4v3h5.5v12h3V7H19V4z" />
  </svg>
)

export default IconTitle;
