import React from 'react';

const IconCategory = (props) => (
  <svg {...props} height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill="currentColor" d="M12 2l-5.5 9h11z" />
    <circle fill="currentColor" cx="17.5" cy="17.5" r="4.5" />
    <path fill="currentColor" d="M3 13.5h8v8H3z" />
  </svg>
)

export default IconCategory;
