import React from 'react';

const IconPlayCircle = (props) => (
  <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <path fill="currentColor" d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M9.5,16.5v-9l7,4.5L9.5,16.5z" />
    </g>
  </svg>
)

export default IconPlayCircle;
