import React from 'react';

const IconVerticalAlignBottom = (props) => (
  <svg {...props} height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M16 13h-3V3h-2v10H8l4 4 4-4zM4 19v2h16v-2H4z" />
  </svg>
)

export default IconVerticalAlignBottom;
