import React from 'react';

const IconBorderRadiusSide = ({ side, editAll, ...props }) => (
    <svg {...props} width="22" height="22" viewBox="0 0 24 24">
        <path
            d="M3 9V4a1 1 0 011-1h5"
            strokeWidth="1.5"
            fill="none"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'top' || editAll ? '1' : '0.3'}></path>
        <path
            d="M21 9V4a1 1 0 00-1-1h-5"
            strokeWidth="1.5"
            fill="none"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'right' || editAll ? '1' : '0.3'}></path>
        <path
            d="M21 15v5a1 1 0 01-1 1h-5"
            strokeWidth="1.5"
            fill="none"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'bottom' || editAll ? '1' : '0.3'}></path>
        <path
            d="M3 15v5a1 1 0 001 1h5"
            strokeWidth="1.5"
            fill="none"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'left' || editAll ? '1' : '0.3'}></path>
    </svg>
);

export default IconBorderRadiusSide;
