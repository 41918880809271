import React from 'react';

const IconTouchApp = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M9 11.24V7.5a2.5 2.5 0 0 1 5 0v3.74c1.21-.81 2-2.18 2-3.74C16 5.01 13.99 3 11.5 3S7 5.01 7 7.5c0 1.56.79 2.93 2 3.74zm9.84 4.63l-4.54-2.26c-.17-.07-.35-.11-.54-.11H13v-6c0-.83-.67-1.5-1.5-1.5S10 6.67 10 7.5v10.74c-3.6-.76-3.54-.75-3.67-.75c-.31 0-.59.13-.79.33l-.79.8l4.94 4.94c.27.27.65.44 1.06.44h6.79c.75 0 1.33-.55 1.44-1.28l.75-5.27c.01-.07.02-.14.02-.2c0-.62-.38-1.16-.91-1.38z"
        />
    </svg>
);

export default IconTouchApp;
