import React from 'react';

const IconRoundedCorner = (props) => (
  <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <g>
          <path fill="currentColor" d="M19,19h2v2h-2V19z M19,17h2v-2h-2V17z M3,13h2v-2H3V13z M3,17h2v-2H3V17z M3,9h2V7H3V9z M3,5h2V3H3V5z M7,5h2V3H7V5z M15,21h2v-2h-2V21z M11,21h2v-2h-2V21z M15,21h2v-2h-2V21z M7,21h2v-2H7V21z M3,21h2v-2H3V21z M21,8c0-2.76-2.24-5-5-5h-5v2h5 c1.65,0,3,1.35,3,3v5h2V8z" />
        </g>
      </g>
    </g>
  </svg>
)

export default IconRoundedCorner;
