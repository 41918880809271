import React from 'react';

const IconAlignEnd = (props) => (
  <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
    <rect fill="none" height="24" width="24" />
    <path fill="currentColor" d="M20,2h2v20h-2V2z M2,10h16V7H2V10z M8,17h10v-3H8V17z" />
  </svg>
)

export default IconAlignEnd;
