import React from 'react';

const IconDrag = (props) => (
    <svg width="16" height="24" viewBox="0 0 16 24" {...props}>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="#FFF" fillRule="nonzero" transform="translate(.5)">
                <path d="M6 21c0 1.65-1.35 3-3 3s-3-1.35-3-3 1.35-3 3-3 3 1.35 3 3zM3 9c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm0-9C1.35 0 0 1.35 0 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm9 6c1.65 0 3-1.35 3-3s-1.35-3-3-3-3 1.35-3 3 1.35 3 3 3zm0 3c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm0 9c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3z"></path>
            </g>
        </g>
    </svg>
);

export default IconDrag;
