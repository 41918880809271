import React from 'react';

const IconLink = (props) => (
    <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <defs>
            <path id="path-1" d="M0 0L16 0 16 16 0 16z"></path>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(4 4)">
                <mask id="mask-2" fill="#fff">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <path
                    fill="currentColor"
                    fillRule="nonzero"
                    d="M2.6 8c0-1.14.927-2.067 2.067-2.067h2.667V4.666H4.667a3.335 3.335 0 000 6.667h2.667v-1.267H4.667A2.068 2.068 0 012.6 7.999zm2.733.666h5.334V7.333H5.334v1.333zm6-4H8.668v1.267h2.667c1.14 0 2.066.926 2.066 2.066 0 1.14-.927 2.067-2.066 2.067H8.667v1.267h2.667a3.335 3.335 0 000-6.667z"
                    mask="url(#mask-2)"></path>
            </g>
        </g>
    </svg>
);

export default IconLink;
