import React from 'react';

const IconSpaceBetween = (props) => (
    <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <clipPath id="a">
            <path d="M0 0h24v24H0z" transform="rotate(-90 12 12)"></path>
        </clipPath>
        <g clipPath="url(#a)">
            <path fill="#fff" d="M0 24V0h24v24z"></path>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M4 2H2v20h2V12l4 4v-3h8v3l4-4-4-4v3H8V8l-4 4zm18 20h-2V2h2z"
                clipRule="evenodd"></path>
        </g>
    </svg>
);

export default IconSpaceBetween;
