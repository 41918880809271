import React from 'react';

const IconMargin = (props) => (
    <svg {...props} height="24" viewBox="0 0 24 24" width="24">
        <rect
            width="21"
            height="21"
            x="0.75"
            y="0.75"
            stroke="currentColor"
            fill="none"
            strokeWidth="1.5"
            rx="1.25"></rect>
        <path
            fill="currentColor"
            d="M16 6v10h-1.511V9.54c0-.257.012-.536.038-.836l-2.787 5.678c-.132.272-.334.408-.606.408h-.242c-.272 0-.474-.136-.606-.408l-2.82-5.699c.014.152.024.302.033.45.008.147.012.283.012.408V16H6V6h1.295c.076 0 .142.002.197.007a.464.464 0 01.147.034.295.295 0 01.121.09.7.7 0 01.109.166l2.761 5.554a12.416 12.416 0 01.383.94c.06-.17.12-.334.185-.49.063-.162.131-.317.204-.464l2.723-5.54a.702.702 0 01.108-.166.364.364 0 01.122-.09.537.537 0 01.153-.034A2.42 2.42 0 0114.705 6H16z"></path>
    </svg>
);

export default IconMargin;
