import IconPlayCircle from './icon-play-circle';
import IconExpandMore from './icon-expand-more';
import IconDrag from './icon-drag';
import IconTimer from './icon-timer';
import IconAddCircle from './icon-add-circle';
import IconAlignCenter from './icon-align-center';
import IconAlignEnd from './icon-align-end';
import IconAlignStart from './icon-align-start';
import IconArrowDropdown from './icon-arrow-dropdown';
import IconLock from './icon-lock';
import IconLockOpen from './icon-lock-open';
import IconCorners from './icon-corners';
import IconTextAlignLeft from './icon-text-align-left';
import IconTextAlignCenter from './icon-text-align-center';
import IconTextAlignRight from './icon-text-align-right';
import IconRoundedCorner from './icon-rounded-corner';
import IconSquare from './icon-square';
import IconSquareOutlined from './icon-square-outlined';
import IconOpenFullscreen from './icon-open-fullscreen';
import IconCloseFullscreen from './icon-close-fullscreen';
import IconPauseCircle from './icon-pause-circle';
import IconWallpaper from './icon-wallpaper';
import IconTitle from './icon-title';
import IconCategory from './icon-category';
import IconMenu from './icon-menu';
import IconMenuOpen from './icon-menu-open';
import IconSettings from './icon-settings';
import IconArrowBack from './icon-arrow-back';
import IconRedo from './icon-redo';
import IconUndo from './icon-undo';
import IconImage from './icon-image';
import IconRectangle from './icon-rectangle';
import IconRectangleOpen from './icon-rectangle-open';
import IconCircle from './icon-circle';
import IconCircleOutlined from './icon-circle-outlined';
import IconTriangle from './icon-triangle';
import IconLine from './icon-line';
import IconCheckboxBlank from './icon-checkbox-blank';
import IconCheckboxChecked from './icon-checkbox-checked';
import IconSelectAll from './icon-select-all';
import IconVisibility from './icon-visibility';
import IconVisibilityOff from './icon-visibility-off';
import IconCancel from './icon-cancel';
import IconAddToHomescreen from './icon-add-to-homescreen';
import IconDesignServices from './icon-design-services';
import IconAdd from './icon-add';
import IconBolt from './icon-bolt';
import IconFolder from './icon-folder';
import IconEdit from './icon-edit';
import IconDelete from './icon-delete';
import IconReset from './icon-reset';
import IconClose from './icon-close';
import IconDownward from './icon-downward';
import IconMovie from './icon-movie';
import IconVerticalAlignBottom from './icon-vertical-align-bottom';
import IconVerticalAlignTop from './icon-vertical-align-top';
import IconVerticalAlignCenter from './icon-vertical-align-center';
import IconMusicNote from './icon-music-note';
import IconTouchApp from './icon-touch-app';
import IconMargin from './icon-margin';
import IconMarginSide from './icon-margin-side';
import IconPadding from './icon-padding';
import IconPaddingSide from './icon-padding-side';
import IconBorderRadiusSide from './icon-border-radius-side';
import IconLink from './icon-link';
import IconSpaceBetween from './icon-space-between';
import IconSpaceEvenly from './icon-space-evenly';

export {
    IconPlayCircle,
    IconExpandMore,
    IconDrag,
    IconTimer,
    IconAddCircle,
    IconTextAlignLeft,
    IconTextAlignCenter,
    IconTextAlignRight,
    IconAlignCenter,
    IconAlignEnd,
    IconAlignStart,
    IconArrowDropdown,
    IconLock,
    IconLockOpen,
    IconCorners,
    IconRoundedCorner,
    IconSquare,
    IconSquareOutlined,
    IconOpenFullscreen,
    IconCloseFullscreen,
    IconPauseCircle,
    IconWallpaper,
    IconTitle,
    IconCategory,
    IconMenu,
    IconMenuOpen,
    IconSettings,
    IconArrowBack,
    IconUndo,
    IconRedo,
    IconImage,
    IconRectangle,
    IconRectangleOpen,
    IconCircle,
    IconCircleOutlined,
    IconTriangle,
    IconLine,
    IconCheckboxBlank,
    IconCheckboxChecked,
    IconSelectAll,
    IconVisibility,
    IconVisibilityOff,
    IconCancel,
    IconAddToHomescreen,
    IconDesignServices,
    IconAdd,
    IconBolt,
    IconFolder,
    IconEdit,
    IconDelete,
    IconReset,
    IconClose,
    IconDownward,
    IconMovie,
    IconVerticalAlignTop,
    IconVerticalAlignBottom,
    IconVerticalAlignCenter,
    IconMusicNote,
    IconTouchApp,
    IconMargin,
    IconMarginSide,
    IconPadding,
    IconPaddingSide,
    IconBorderRadiusSide,
    IconLink,
    IconSpaceBetween,
    IconSpaceEvenly
};
