import React from 'react';

const IconCorners = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24">
    <path d="M0,0H24V24H0Z" fill="none" />
    <path fill="currentColor" style={{ opacity: 0.25 }} d="M5,15H3v4a2.006,2.006,0,0,0,2,2H9V19H5Zm14,4H15v2h4a2.006,2.006,0,0,0,2-2V15H19ZM19,3H15V5h4V9h2V5A2.006,2.006,0,0,0,19,3Z" />
    <path fill="currentColor" d="M3,5V9H5V5H9V3H5A2.006,2.006,0,0,0,3,5Z" />
  </svg>
)

export default IconCorners;
