import React from 'react';

const IconMusicNote = (props) => (
  <svg {...props} height="24px" viewBox="-5 -2 24 24" width="24px" fill="currentColor">
    <g><rect fill="none" height="24" width="24" /></g>
    <g><g><path d="M6 0V9.28C5.53 9.11 5.03 9 4.5 9C2.01 9 0 11.01 0 13.5C0 15.99 2.01 18 4.5 18C6.81 18 8.7 16.25 8.95 14H9V3H13V0H6Z" /></g></g>
  </svg>
)


export default IconMusicNote;
