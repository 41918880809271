import React from 'react';

const IconPaddingSide = ({ side, editAll, ...props }) => (
    <svg {...props} width="22.5" height="22" viewBox="0 0 23 23">
        <path
            fill="currentColor"
            d="M11.417 10.967c.345 0 .646-.042.903-.126a1.74 1.74 0 00.637-.371c.173-.163.301-.36.385-.588a2.28 2.28 0 00.126-.777c0-.27-.042-.516-.126-.735a1.453 1.453 0 00-.378-.56 1.646 1.646 0 00-.637-.35 2.936 2.936 0 00-.91-.126H10.01v3.633h1.407zm0-5.089c.677 0 1.262.08 1.757.238.495.159.903.38 1.225.665.322.285.56.625.714 1.022.159.397.238.83.238 1.302 0 .49-.082.94-.245 1.351a2.804 2.804 0 01-.735 1.05c-.327.294-.737.523-1.232.686-.49.163-1.064.245-1.722.245H10.01V16H8.127V5.878h3.29z"></path>
        <path
            d="M4.5 0.5L17.5 0.5"
            strokeWidth="1.5"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'top' || editAll ? '1' : '0.3'}></path>
        <path
            d="M21.5 4.5L21.5 16.5"
            strokeWidth="1.5"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'right' || editAll ? '1' : '0.3'}></path>
        <path
            d="M4.5 20.5L17.5 20.5"
            strokeWidth="1.5"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'bottom' || editAll ? '1' : '0.3'}></path>
        <path
            d="M0.5 4.5L0.5 16.5"
            strokeWidth="1.5"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'left' || editAll ? '1' : '0.3'}></path>
    </svg>
);

export default IconPaddingSide;
