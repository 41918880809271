import React from 'react';

const IconMarginSide = ({ side, editAll, ...props }) => (
    <svg {...props} width="22.5" height="22" viewBox="0 0 23 23">
        <path
            fill="currentColor"
            d="M17 6v10h-1.663V9.54c0-.257.014-.536.043-.836l-3.066 5.678c-.145.272-.367.408-.667.408h-.266c-.3 0-.522-.136-.667-.408l-3.1-5.699c.014.152.025.302.035.45.009.147.014.283.014.408V16H6V6h1.424c.084 0 .157.002.218.007.06.005.114.016.16.034a.325.325 0 01.134.09.698.698 0 01.12.166l3.037 5.554a11.634 11.634 0 01.42.94c.066-.17.134-.334.204-.49.07-.162.146-.317.225-.464l2.995-5.54a.698.698 0 01.12-.166.4.4 0 01.133-.09.64.64 0 01.168-.034c.061-.005.134-.007.218-.007H17z"></path>
        <path
            d="M4.5 0.5L17.5 0.5"
            strokeWidth="1.5"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'top' || editAll ? '1' : '0.3'}></path>
        <path
            d="M21.5 4.5L21.5 16.5"
            strokeWidth="1.5"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'right' || editAll ? '1' : '0.3'}></path>
        <path
            d="M4.5 20.5L17.5 20.5"
            strokeWidth="1.5"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'bottom' || editAll ? '1' : '0.3'}></path>
        <path
            d="M0.5 4.5L0.5 16.5"
            strokeWidth="1.5"
            stroke={editAll ? '#000000' : '#2971EB'}
            opacity={side === 'left' || editAll ? '1' : '0.3'}></path>
    </svg>
);

export default IconMarginSide;
