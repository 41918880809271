import React from 'react';

const IconImage = (props) => (
  <svg {...props} viewBox="0 0 24 24" width="24" height="24">
    <g>
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path fill="currentColor" d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"></path>
    </g>
  </svg>
)

export default IconImage;
