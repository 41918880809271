import React from 'react';

const IconVerticalAlignTop = (props) => (
  <svg {...props} height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M8 11h3v10h2V11h3l-4-4-4 4zM4 3v2h16V3H4z" />
  </svg>
)

export default IconVerticalAlignTop;
