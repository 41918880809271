import React from 'react';

const IconArrowDropdown = (props) => (
  <svg {...props} height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M7 10l5 5 5-5z" fill="currentColor" />
  </svg>
)

export default IconArrowDropdown;
