import React from 'react';

const IconPauseCircle = (props) => (
  <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <path fill="currentColor" d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M11,16H9V8h2V16z M15,16h-2V8h2V16z" />
      </g>
    </g>
  </svg>
)

export default IconPauseCircle;
