import React from 'react';

const IconOpenFullscreen = props => (
  <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
    <rect fill="none" height="24" width="24"/>
    <polygon fill="currentColor" points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71"/>
  </svg>
)

export default IconOpenFullscreen;
