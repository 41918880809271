import React from 'react';

const IconSquareOutlined = (props) => (
  <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" fill="currentColor">
    <g><rect fill="none" height="24" width="24" /></g>
    <g><g><path d="M3,3v18h18V3H3z M19,19H5V5h14V19z" /></g></g>
  </svg>
)

export default IconSquareOutlined;
