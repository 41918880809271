import React from 'react';

const IconSpaceEvenly = (props) => (
    <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <clipPath id="a">
            <path d="M0 0h24v24H0z" transform="rotate(-90 12 12)"></path>
        </clipPath>
        <clipPath id="b">
            <path d="M0 0h24v24H0z"></path>
        </clipPath>
        <g clipPath="url(#a)">
            <path fill="#fff" d="M0 24V0h24v24z"></path>
            <g clipPath="url(#b)">
                <path fill="currentColor" d="M4 22H2V2h2zM22 2h-2v20h2zm-8.5 5h-3v10h3z"></path>
            </g>
        </g>
    </svg>
);

export default IconSpaceEvenly;
