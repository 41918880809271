import React from 'react';

const IconAlignStart = (props) => (
  <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
    <rect fill="none" height="24" width="24" />
    <path fill="currentColor" d="M4,22H2V2h2V22z M22,7H6v3h16V7z M16,14H6v3h10V14z" />
  </svg>
)

export default IconAlignStart;
