import React from 'react';

const IconLockOpen = (props) => (
  <svg {...props} enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
    </g>
    <g>
      <path fill="currentColor" d="M18,8h-1V6c0-2.76-2.24-5-5-5C9.72,1,7.73,2.54,7.16,4.75C7.02,5.29,7.34,5.83,7.88,5.97C8.41,6.11,8.96,5.79,9.1,5.25 C9.44,3.93,10.63,3,12,3c1.65,0,3,1.35,3,3v2H6c-1.1,0-2,0.9-2,2v10c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V10C20,8.9,19.1,8,18,8z M12,17c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,17,12,17z" />
    </g>
  </svg>
)

export default IconLockOpen;
